<template>
  <div class="home">
    <div id="page-hero" class="pt-6 pb-2 pt-md-12 mb-0 white--text container--fluid">
      <div class="container">
        <v-row>
          <v-col cols="12" sm="12">
            <h1 class="display-2 mb-4 mt-16">Challenge and Results Platform</h1>
            <p>
              The {{ tenant.name}} platform powers connected and engaging events!
              Our device agnostic platform allows users to connect any fitness tracker and keep using the device or app they already love. All our features are aimed at providing best-in-class user experiences for participants and event organizers.
            </p>
            <p>
              Our mission is to encourage everyone to be active and enjoy the great outdoors. 
              Our platform offers a solution to endurance sport events and challenges worldwide. 
              Whether it's an in-person, hybrid, or virtual event.
              Whether it's for a sport event, a trail organization, a local community, or to reach a fundraising goal. 
              We are here to help you succeed.
            </p>
            <p class="mb-0">
              <v-btn class="mr-4 mb-4" color="white" :to="{name:'events'}">Explore events</v-btn>
              <v-btn class="mb-4" color="white" outlined :to="{name: 'eventmanagerCreate'}">Create your event</v-btn>
            </p>
          </v-col>
        </v-row>
      </div>
    </div>
    <div id="page-customers" class="mt-0 white--text">
      <div class="container pb-0">
        <v-row>
          <v-col class="pb-4">
            <h1 class="headline">Trusted by world-class event organizers</h1>
            <p>
              The  {{ tenant.name}} Platform is used by world-renowned event organizers and managements organizations all across the world. Some of our key partners are:
            </p>
            <SectionCustomers showcase/>
          </v-col>
        </v-row>
      </div>
    </div>

    <div id="page-integrations" class="grey lighten-4">
    <v-container class="pb-0">
      <v-row>
        <v-col class="pb-0">
          <h3 class="title">Integration with all major fitness trackers</h3>
          <SectionIntegrations/>
          <p class="mb-0 mt-4">
            <v-btn class="mr-4 mb-4" color="primary" outlined href="/features">Explore All Features</v-btn>
            <v-btn class="mb-4" color="primary" outlined href="/contact">Contact Us</v-btn>
          </p>
        </v-col>
      </v-row>
    </v-container>
    </div>

    <v-container id="page-upcoming">
      <v-row>
        <v-col>
          <h3 class="title">Upcoming events</h3>
          <EventGrid :items="events" class=""></EventGrid>
          <p class="mb-0 mt-4">
            <v-btn class="mr-4 mb-4" color="white" href="/events">See all</v-btn>
          </p>
        </v-col>
      </v-row>
    </v-container>
 
    <div class="cta-bar green white--text py-6 py-md-12">
      <div class="container">
        <v-row>
          <v-col cols="12" md="12">
            <h2 class="subtitle">For in-person, hybrid, and virtual events</h2>
            <h1 class="subtitle">Powering Connected and Engaging Events!</h1>
            <p class="mb-0 mt-4">
              <v-btn class="mr-4 mb-4" color="white" href="/features">Features</v-btn>
              <v-btn class="mb-4" color="white" outlined href="/contact">Contact Us</v-btn>
            </p>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-container v-if="tenant.showFeatures" class="features">
      <v-row>
        <v-col cols=12>
          <h3 class="title">Features </h3>
          <p>Explore the features  {{ tenant.name}} has to offer to <strong>make a success</strong> of your event or challenge.</p>
        </v-col>
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-watch</v-icon>
          <h4>Auto-sync with Garmin, Fitbit, Polar, Apple Watch and more</h4>
          <p>
            Activities tracked with Garmin, Polar, Suunto, Fitbit or COROS devices and the MapMyRun app are automatically synced and added to results making it extremely easy for your participants to make every km/mile count!
          </p>

          <p>
            Next to our native integrations we also support manual entry or upload of any other gpx file.  
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-stopwatch</v-icon>
          <span class="plus">+</span>
          <v-icon x-large color="primary">fadl fa fa-ruler</v-icon>
          <span class="plus">+</span>
          <v-icon x-large color="primary">fadl fa fa-mountain</v-icon>
          <h4>Race result, total distance, total elevation and more</h4>
          <p>
            We support all popular challenge modes: best result, total distance or total elevation. Even better: you can combine them in the same virtual challenge!
          </p>
          <p> 
            Whether your virtual event/challenge runs for a day, a week or even multiple months, whether it is a single sport or a multi-sport event, you can host it on  {{ tenant.name}}.
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-list</v-icon>
          <h4>In-person race results</h4>
          <p>
            Easily upload the official race results from an in-person race. Whether your event is in-person only or combines an in-person race with a do-anywhere challenge, we can support your event and allow you to publish all results at one location. 
          </p>
          <p>
            Use our app to send push-notifications to participants and post photos or videos to the feed in our free app to give your participants the best event experience.
          </p>
        </v-col>
        
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-mobile</v-icon>
          <h4>App</h4>
          <p>
            Use our free app to publish your results and share the latest updates with your participants. The app is fully customizable and allows you to upload sponsor logos and customize the design and layout for your event.
          </p>
          <p>Our app has an integrated step and activity tracker, allows manual entry, and also allows participants to connect their favorite fitness app or watch. This allows everyone to join your event, regardless of the tech they use!</p>
          <p>
            For larger events we can create customized event apps. Please reach out to us to learn more.
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-swimmer fa-flip-horizontal</v-icon>
          <span class="plus">+</span>
          <v-icon x-large color="primary">fadl fa fa-bicycle</v-icon>
          <span class="plus">+</span>
          <v-icon x-large color="primary">fadl fa fa-running</v-icon>
          <h4>All sports and combinations</h4>
          <p>
            We support all popular sports and you can combine them all in the same challenge.
          </p>
          <p> 
            Use our platform to host virtual triathlons, multi-sport events or any combinations of different activity types.
          </p>
        </v-col>
        
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-map</v-icon>
          <h4>Progress on map</h4>
          <p>
            Make your challenge more fun by mapping the progress on a real map. Simply upload your virtual course map and we'll map the progress on the map.
          </p>
          <p>
            We show all participants on the same map and have a powerful search option to find your own or team members' results easily!
          </p>
        </v-col>
        
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-shield</v-icon>
          <h4>Badges</h4>
          <p>
            Create your own intermediate goals based on distance or elevation completed and award badges to keep your participants motivated to make it to the (virtual) finish line! Use our consecutive challenge to motivate your participants to keep going and our location based badges for virtual treasure hunts or embed local content into your challenge.
          </p>
          <p>
            Easily customize the layout or even use custom images to make the badges unique to your challenge. You can optionally enable and customize email messages to be sent whenever a badge is awarded and view overall statistics of each badge on your race result page.
          </p>
        </v-col>
        
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-bullseye</v-icon>
          <h4>Collective progress &amp; Participation Mode</h4>
          <p>
            We're into this together, so let's work together in reaching our common goals!
            Easily set a collective target and everyone's effort will count toward reaching this collective goal!
          </p>
          <p> 
            Enable our participation mode for non-competitive challenges to change the leaderboard into a ticker board where the latest activity 
            is shown at the top to give everyone their moment in the spotlights, regardless of distance, pace, or speed!
          </p>
        </v-col>
        
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-user</v-icon>
          <span class="plus">+</span>
          <v-icon x-large color="primary">fadl fa fa-users</v-icon>
          <h4>Individual or team races</h4>
          <p>
            Create both individual or team challenges, or combinations of both! With the team functionality any group can work together to reach their common goals and compete with the other registered teams for first place!
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-paint-roller</v-icon>
          <h4>Customize design</h4>
          <p> 
            Make it yours by customizing the design of your virtual event/challenge. Easily upload your logo and artwork to make your virtual event look beautiful.
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-tools</v-icon>
          <h4>Powerful Management Tools</h4>
          <p> 
            Use our easy to use, yet powerful management tools to manage your virtual challenge and results. All features can be configured using our self-service tools. Need help? No worries, we have extensive knowledge base articles available or can help you with an onboarding trainings session.
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-tags</v-icon>
          <h4>Simple pricing structure</h4>
          <p> 
            <router-link to="/pricing">Simple and fair pricing</router-link> that scales with your event. No setup-fees or minimum costs.
          </p>
        </v-col>
      </v-row>
    </v-container>

    <div class="cta-bar secondary white--text py-6 py-md-12">
      <div class="container">
        <v-row>
          <v-col cols="12" md="12">
            <h2 class="subtitle">Our mission:</h2>
            <h1 class="subtitle">"Make it easier and more fun to be active, and keep it up!"</h1>
            <p class="mb-0 mt-4">
              <v-btn class="mr-4 mb-4" color="white" href="/about">About Us</v-btn>
              <v-btn class="mb-4" color="white" outlined href="/contact">Contact Us</v-btn>
            </p>
          </v-col>
        </v-row>
      </div>
    </div>

  </div>
  
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import eventService from "@/services/eventService";
import EventGrid from "@/components/EventGrid.vue";
import SectionIntegrations from "@/components/SectionIntegrations.vue";
import SectionCustomers from "@/components/SectionCustomers.vue";
import siteData from '@/data/site.json'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "home",
  components: {
    EventGrid,
    SectionIntegrations,
    SectionCustomers,
  },
  data() {
    return {
      tenant: tenant,
      events: null,
      siteData: siteData,
    };
  },
  async mounted() {
    this.events = (await eventService.upcoming(10)).data.data;
  }, 
};
</script>

<style lang="scss" scoped>
  .features {
    i { background-color: #F4652422; border-radius: 50%; padding: 20px; margin-top: 20px; }
    .plus {
      margin: 0 20px; line-height: 40px;
      color: #F46524;
    }
    h4 { margin-top: 8px; }
  }

  /* CLS improvements:  */
  #page-hero { min-height: 358px;}
  #page-customers { min-height: 340px;}
  #page-integrations { min-height: 436px;}
  #page-upcoming { min-height: 940px;}
  .cta-bar { min-height: 332px;}

  @media (max-width: 500px) {
    #page-hero { min-height: 750px;}
    #page-customers { min-height: 560px;}
    #page-integrations { min-height: 625px;}
    #page-upcoming { min-height: 2844px;}
    .cta-bar { min-height: 332px;}
  }
</style> 